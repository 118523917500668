import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <header>
      <p>Our website is under construction.</p>
        <h1>Trusted home construction in North Texas.</h1>
        
        <br />
        <a href="mailto:john@topformllc.com"><b>Request a Quote</b></a>
      </header>

    <hr />
    <section>
        <header>
            <h2>Services</h2>
        </header>
        <aside>
            <h3>Renovate</h3>
            <p>We take something outdated in your house and turn it into something amazing.</p>
        </aside>
        <aside>
            <h3>Repair</h3>
            <p>We'll find the problem quickly and fix it the right way the first time.</p>
        </aside>
        <aside>
            <h3>Build</h3>
            <p>You have big dreams and need something built from scratch to meet your exact needs.</p>
        </aside>
    </section>
    <hr />

    <section>
      <header>
        <h2>Make your house your dream home</h2>
        <p>Ready to start a project? <a href="mailto:john@topformllc.com">Request a Quote</a></p>
      </header>
    </section>
   
  </Layout>
) 

export default IndexPage
